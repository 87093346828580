import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  categories: [],
  finishedFetchingProducts: false,
  loadingProducts: true,
  loadingCategories: true,
  productsAccordionIsOpen: false,
  showCategories: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    //Products
    loadProducts(state, action) {
      state.products = action.payload;
    },

    startLoadingProducts(state) {
      state.loadingProducts = true;
    },

    finishLoadingProducts(state) {
      state.loadingProducts = false;
    },

    finishFetchingProducts(state) {
      state.finishedFetchingProducts = true;
    },

    //Categories
    loadCategories(state, action) {
      state.categories = action.payload;
    },

    startLoadingCategories(state) {
      state.loadingCategories = true;
    },

    finishLoadingCategories(state) {
      state.loadingCategories = false;
    },

    toggleProductsAccordion(state, action) {
      state.productsAccordionIsOpen = action.payload;
    },

    toggleNavProductsCategories(state, action) {
      state.showCategories= action.payload;
    }
  },
});

export const productsActions = productsSlice.actions;
export default productsSlice;
