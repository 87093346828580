import React, {useEffect, useState} from "react";
import { Collapse, Divider, Toolbar } from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { toggleNavProductsCategoriesWithDelay } from '../../../store/products-actions';
import NavProductCategory from "./NavProductCategory";

const NavProductCategories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [batteriesCategory] = useSelector((state) => state.products.categories);
  const showCategories = useSelector((state) => state.products.showCategories);
  const [expandCategories, setExpandCategories] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExpandCategories(showCategories)
    }, 100);

    return () => {
      clearTimeout(timer);
    }
  }, [showCategories])

  const toggleProductsCategoryBar = (value) => {
      dispatch(toggleNavProductsCategoriesWithDelay(value));
  };


  const productCategories = batteriesCategory?.children;
  return (
    <Collapse in={expandCategories} onMouseEnter={() => toggleProductsCategoryBar(true)} onMouseLeave={() => toggleProductsCategoryBar(false)}>
      <Divider variant="middle" />
      <Toolbar variant="dense" className={classes.toolbar}>
        {productCategories?.map((category) => (
          <NavProductCategory
            key={category.slug}
            text={category.name}
            image={category.assets[0].url}
            slug={category.slug}
          />
        ))}
      </Toolbar>
    </Collapse>
  );
};

// const NavProductCategories = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
//   return (
//     <>
//       {!isMobile &&
//         ReactDOM.createPortal(
//           <NavProductCategoriesToPort />,
//           document.getElementById("nav-product-categories")
//         )}
//     </>
//   );
// };

export default NavProductCategories;
