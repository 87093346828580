import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
} from "@material-ui/core";
import { ShoppingCart, Menu } from "@material-ui/icons";
import { ReactComponent as BannerWithText } from "../../../assets/banner.svg";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

import NavItems from "./NavItems/NavItems";
import useStyles from "./styles";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from 'react-redux';
import { productsActions } from '../../../store/products-slice';
import NavProductCategories from '../../Products/NavProductCategories/NavProductCategories';
const Navbar = ({ toggleDrawer }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const cart = useSelector(state => state.cart.cart)
  const totalItemsInCart = cart?.total_items;
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const toggleProductsCategoryBar = (value) => {
    setTimeout(() => {
      dispatch(productsActions.showNavProductsCategories(value));
    }, 100);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} color="inherit" >
        <Toolbar variant="regular" className={classes.toolbar}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Typography variat="h6" className={classes.title} color="inherit">
              {isTablet ? (
                <Logo style={{ height: "70px", width: "100%" }} />
              ) : (
                <BannerWithText style={{ height: "70px", width: "100%", marginRight: '1rem'}} />
              )}
            </Typography>
          </Link>

          {/* <div className={classes.grow} /> */}
          {!isMobile && <NavItems isRow className={classes.navItems} />}
          <div className={classes.grow} />
          <div className={classes.button}>
            {location.pathname !== "/cart" ? (
              <IconButton
                component={Link}
                to="/cart"
                aria-label="Show cart items"
                color="inherit"
              >
                <Badge badgeContent={totalItemsInCart} color="primary">
                  <ShoppingCart fontSize="medium" />
                </Badge>
              </IconButton>
            ) : (
              <IconButton style={{ visibility: "hidden" }}>
                <ShoppingCart fontSize="medium" />
              </IconButton>
            )}
          </div>
          {isMobile && (
            <IconButton onClick={toggleDrawer(true)} aria-label="Open Menu">
              <Menu fontSize="medium" />
            </IconButton>
          )}
        </Toolbar>
        {/* <div id='nav-product-categories'/>  */}
      {!isMobile && <NavProductCategories onMouseEnter={() => {toggleProductsCategoryBar(true)}}/>}
        {/* This is a where NavProductCategories is ported to when accessing the /products/ route */}
      </AppBar>
    </>
  );
};

export default Navbar;
