import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  order: {},
  error: "",
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    loadOrder(state, action) {
      state.order = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    }
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice;
