import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Image from "material-ui-image";
import useStyles from "./styles";
const NavProductCategory = ({ text, image, slug }) => {
  const classes = useStyles();
  return (
    <Link to={`/products?category=${slug}`}>
      <span className={classes.navProductCategory}>
        <Image
          src={image}
          alt={text}
          spacing={0}
          
          style={{width: '65px', height: '65px', padding: '0'}}
          imageStyle={{width: '65px', height: '65px'}}
        />
        <Typography variant="body1" color="textSecondary">
          {text}
        </Typography>
      </span>
    </Link>
  );
};

export default NavProductCategory;
