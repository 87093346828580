import React from "react";
import { Select, MenuItem, Grid, InputLabel, CircularProgress } from "@material-ui/core";
import useStyles from './select-input.styles';
const SelectInput = ({
  values, onChange, label, selectedValue, loading
}) => {
  const classes = useStyles();


  return (
    <Grid item xs={12} sm={6} className={classes.grid}>
      <InputLabel>{label}
        
      </InputLabel> 
      {loading ? <CircularProgress size="1.5rem" className={classes.spinner}/> : null}
      <Select value={selectedValue} fullWidth onChange={onChange}> 
      
        {values.map(value => {
          return <MenuItem key={value.id} value={value.id}> {value.label} </MenuItem>
        })}
      </Select>
    </Grid>
  );
};

export default SelectInput;
