import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddShoppingCart } from "@material-ui/icons";

import useStyles from "./styles";

import { useDispatch } from "react-redux";
import { addToCart } from "../../../store/cart-actions";

const Product = ({ product }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Card className={classes.root}>
      <CardMedia
        component={Link}
        to={`/products/${product.id}`}
        className={classes.media}
        image={product.media.source}
        title={product.name}
      />
      <CardContent disableSpacing className={classes.cardContent}>
        <Typography component={Link} to={`/products/${product.id}`} variant="h5" gutterBottom>
          {product.name}
        </Typography>

      </CardContent>
      <CardActions disleSpacing className={classes.cardActions}>
        <Typography variant="h6" color="primary">
          {product.price.formatted_with_symbol}
        </Typography>
        <IconButton
          aria-label="Add to Cart"
          onClick={() => {
            dispatch(addToCart(product.id, 1));
          }}
          color="primary"
        >
          <AddShoppingCart />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default Product;
