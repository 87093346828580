import React from "react";
import ImageGallery from "react-image-gallery";
import useStyles from "../styles";

const ProductImageGallery = ({ images }) => {
  const classes = useStyles();
  const normalizedImages = images.map((image) => ({
    original: image.url,
    thumbnail: image.url,
    originalHeight: "600px",
    thumbnailWidth: "50px",
  }));
  return (
    <div className={classes.galleryContainer}>
      <ImageGallery
        items={normalizedImages}
        showThumbnails
        thumbnailPosition="bottom"
        showPlayButton={false}
        showNav={false}
        showFullscreenButton={false}
        slideOnThumbnailOver={true}
      />
    </div>
  );
};

export default ProductImageGallery;
