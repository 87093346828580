import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: {},
  loading: true,
  totalItems: 0,
  totalPrice: 0,
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loadCart(state, action) {
      state.cart = action.payload;
      state.totalItems = action.payload.length;
    },

    startLoading(state) {
      state.loading = true;
    },

    finishLoading(state) {
      state.loading = false;
    }
  }
});

export const cartActions = cartSlice.actions;
export default cartSlice;