import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { Container } from '@material-ui/core';
import NavItems from "../Navbar/NavItems/NavItems";
import CategoriesAccordion from "./CategoriesAccordion/CategoriesAccordion";
import {ReactComponent as Logo} from "../../../assets/logo.svg";

const SideDrawer = ({ toggleDrawer, drawerIsOpen }) => {

  const navLinks = [
    {path: '/support', text: 'Customer Support'},
    {path: '/gallery', text: 'Media Gallery'},
  ];

  const list = (
    <Box
      sx={{ width: 320 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Container style={{marginTop: '2rem'}}>
      <Logo style={{height: '120px', width: '100%', marginBottom: '2rem'}}/>
      <CategoriesAccordion onClickLink={toggleDrawer(false)}/>
      <NavItems onClickLink={toggleDrawer(false)} providedNavLinks={navLinks}/>
      </Container>
      
    </Box>
  );

  return (
    <Drawer anchor="right" open={drawerIsOpen} onClose={toggleDrawer(false)}>
      {list}
    </Drawer>
  );
};

export default SideDrawer;
