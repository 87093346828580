import cartSlice from "./cart-slice";
import productsSlice from "./products-slice";
import orderSlice from "./order-slice";
import authSlice from "./auth-slice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    products: productsSlice.reducer,
    order: orderSlice.reducer,
    auth: authSlice.reducer,
  },
});

export default store;
