import { Container, Typography } from '@material-ui/core';
import React from 'react'
import useStyles from '../main-styles';

const CustomerSupport = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" align="center">
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h3" gutterBottom>
        Customer Support
      </Typography>
    </Container>
  )
}

export default CustomerSupport
