import React from 'react'
import Carousel from './Carousel/Carousel';
import useStyles from './styles';
import Slogan from './Slogan/Slogan';
import HomeProducts from './HomeProducts/HomeProducts';
import HomeIcons from './HomeIcons/HomeIcons';
const Home = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.toolbar} />
      <Carousel />
      <br />
      <HomeIcons />
      <Slogan />
      <HomeProducts />
      <div className={classes.toolbar} />
    </>
  )
}

export default  Home
