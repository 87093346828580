import React from "react";
import { Container, Typography } from "@material-ui/core";
import useStyles from '../main-styles';

const MediaGallery = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" align="center">
    <div className={classes.toolbar} />
    <Typography className={classes.title} variant="h3" gutterBottom>
      Media Gallery
    </Typography>
  </Container>
  );
};

export default MediaGallery;
