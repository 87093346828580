import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: '',
  isLoggedIn: false,
  response: null,
  loading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading =true;
    },

    finishLoading(state) {
      state.loading=false;
    },

    setInvalidResponse(state) {
      state.response = 'Invalid credentials'
    },

    logIn(state, action) {
      localStorage.setItem('refreshToken', action.payload);
      state.token = action.payload;
      state.isLoggedIn = true;
      state.response = "Credentials validated successfully";
    },

    logOut(state) {
      localStorage.removeItem('refreshToken');
      state.token = '';
      state.isLoggedIn = false;
      state.response = null;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
