import React, { useEffect } from "react";
import {
  Products,
  Cart,
  Checkout,
  Layout,
  Home,
  Login,
  ProductDetailed,
} from "./components";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  CircularProgress,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import CustomerSupport from "./components/CustomerSupport/CustomerSupport";
import MediaGallery from "./components/MediaGallery/MediaGallery";

import { useSelector, useDispatch } from "react-redux";
import { fetchCart } from "./store/cart-actions";
import { fetchProducts, fetchCategories } from "./store/products-actions";
import { resendTokenAfterPageRefresh } from "./store/auth-actions";

let theme = createTheme({
  palette: {
    primary: {
      main: "#0f84d4",
      light: "#00adee",
      dark: "#2d3a8b",
    },
    secondary: {
      main: "#737373",
    },

    text: {
      primary: "#000",
      secondary: "#444"
    }
  },
  typography: {
    fontFamily: "Source Sans Pro, Helvetica, sans-serif",
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    fontWeightBold: 600,

    subtitle1: {
      fontSize: 28,
    },
    // body1: {
    //   fontSize: 18
    // }

  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
  breakpoints: {
    keys: ["xxs", "xs", "sm", "md", "lg", "xl"],
    values: {
      xxs: 0,
      xs: 414, // iPhone X and below in portrait mode
      sm: 700,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  ////////////REDUX STUFF
  const cart = useSelector((state) => state.cart.cart);
  const loadingAuth = useSelector((state) => state.auth.loading);
  const loadingCart = useSelector((state) => state.cart.loading);
  const loadingProducts = useSelector((state) => state.products.loadingProducts);
  const loadingCategories = useSelector((state) => state.products.loadingCategories);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  /////////////////

  useEffect(() => {
    dispatch(fetchCart());
    dispatch(fetchProducts());
    dispatch(fetchCategories());
    dispatch(resendTokenAfterPageRefresh());
  }, [dispatch]);

  const appContent = (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/cart">
          {loadingCart && (
            <div className="centered">
              <CircularProgress />
            </div>
          )}
          {!loadingCart && <Cart />}
        </Route>

        <Route exact path="/checkout">
          {cart?.total_items === 0 && <Redirect to="/" />}
          {!loadingCart && <Checkout />}
        </Route>

        <Route exact path="/products">
          { (loadingProducts || loadingCategories) && (
            <div className="centered">
              <CircularProgress />
            </div>
          )}
          {!loadingProducts && !loadingCategories && <Products />}
        </Route>

        <Route exact path="/products/:id">
          {loadingProducts && (
            <div className="centered">
              <CircularProgress />
            </div>
          )}
          {!loadingProducts && <ProductDetailed />}
        </Route>

        <Route exact path="/support">
          <CustomerSupport />
        </Route>

        <Route exact path="/gallery">
          <MediaGallery />
        </Route>

        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Layout>
  );

  return (
    <ThemeProvider theme={theme}>
      {isLoggedIn ? appContent
      : loadingAuth ? (
        <div className="centered">
          <CircularProgress />{" "}
        </div>
      ) : (
        <Login />
      )}
    </ThemeProvider>
  );
}

export default App;
