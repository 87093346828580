import { Divider, List } from "@material-ui/core";
import ProductsNavItem from './ProductsNavItem/ProductsNavItem';
import React from "react";
import NavItem from './NavItem';

const NavItems = ({ isRow, onClickLink, providedNavLinks }) => {

  const defaultNavLinks = [
    // {path: '/products', text: 'Products'},
    {path: '/support', text: 'Customer Support'},
    {path: '/gallery', text: 'Media Gallery'},
  ]

  const navLinks = providedNavLinks ? providedNavLinks : defaultNavLinks;

  return (
    <List style={{display: 'flex', flexDirection: isRow? 'row' : 'column', padding: '0'}}>
      {isRow && <ProductsNavItem />}
      {navLinks.map(navItem => {
        if (isRow)
          return <NavItem key={navItem.text} path={navItem.path} text={navItem.text}/>
        else 
          return <>
            <NavItem key={navItem.text} path={navItem.path} text={navItem.text} onClick={onClickLink}/>            
            <br />
            <Divider />
            <br />
          </>
      } )}
    </List>
  );
};

export default NavItems;
