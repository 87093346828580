import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import React from "react";
import useStyles from "./styles";
import { useTheme, Typography, Container, Grid } from "@material-ui/core";


const HomeIcons = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.content}>
      <Typography variant="h4" gutterBottom>
        We create the most innovative, versatile power solution for your
        devices.
      </Typography>
      <br />
      <Container maxWidth="md" >
        <Grid container className={classes.row}>

          <Grid xs={6} sm={4}  item className={classes.iconWrapper}>
            <BatteryChargingFullIcon
              color={theme.palette.primary.main}
              style={{ fontSize: "90" }}
            />
            <Typography variant="h5">Fast charging</Typography>
          </Grid>

          <Grid xs={6} sm={4} item className={classes.iconWrapper}>
            <AutorenewIcon
              color={theme.palette.primary.main}
              style={{ fontSize: "90" }}
            />
            <Typography variant="h5">Renewable charging</Typography>
          </Grid>

          <Grid xs={12} sm={4} item className={classes.iconWrapper}>
            <NextWeekIcon
              color={theme.palette.primary.main}
              style={{ fontSize: "90" }}
            />
            <Typography variant="h5">Portable</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HomeIcons;
