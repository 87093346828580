import { commerce } from "../lib/commerce";
import { orderActions } from "./order-slice";

export const captureCheckout = (checkoutTokenId, newOrder) => {
  return async (dispatch) => {
    try {
      const incomingOrder = await commerce.checkout.capture(
        checkoutTokenId,
        newOrder
      );
      dispatch(orderActions.loadOrder(incomingOrder));

    } catch (error) {
      dispatch(orderActions.setError(error.data.error.message));
    }
  };
};
