import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: '#eee',
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  title: {
    marginTop: '5%',
  },
  link: {
    textDecoration: 'none',
    padding: `0 2rem`,
    transition: 'all 0.3s',
    '&:visited': {
      color: 'black',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  activeLink: {
    color: `${theme.palette.primary.main}!important`,
  },

  productsNavItemWithIcon: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '1.5rem 0 1.5rem 0'
  },

  expandMoreIcon: {
    marginLeft: '0.3rem',
    transform: 'rotate(0deg)',
    transition: 'all 0.5s'
    
  },

  expandMoreRotated: {
    transform: 'rotate(180deg)',
    marginLeft: '0.3rem',
    transition: 'all 0.5s',
  },


  
}));