import { cartActions } from "./cart-slice";
import { orderActions } from './order-slice';
import { commerce } from "../lib/commerce";

export const fetchCart = () => {

  return async (dispatch) => {
    dispatch(cartActions.startLoading());
    const cart = await commerce.cart.retrieve();
    dispatch(cartActions.loadCart(cart));
    dispatch(cartActions.finishLoading());
  };
};

export const addToCart = (productId, quantity) => {
  return async (dispatch) => {
    dispatch(cartActions.startLoading());
    const item = await commerce.cart.add(productId, quantity);
    dispatch(cartActions.loadCart(item.cart));
    dispatch(cartActions.finishLoading());
  };
};

export const updateCartQty = (productId, quantity) => {
  return async (dispatch) => {
    const response = await commerce.cart.update(productId, { quantity });
    dispatch(cartActions.loadCart(response.cart));
  };
};

export const removeFromCart = (productId) => {
  return async (dispatch) => {
    const response = await commerce.cart.remove(productId);
    dispatch(cartActions.loadCart(response.cart));
    
  };
};

export const emptyCart = () => {
  return async (dispatch) => {
    dispatch(cartActions.startLoading());
    const response = await commerce.cart.empty();
    dispatch(cartActions.loadCart(response.cart));
    dispatch(cartActions.finishLoading());
  };
};

export const refreshCart = () => {
  return async (dispatch) => {
    dispatch(cartActions.startLoading());
    const newCart = await commerce.cart.refresh();
    dispatch(cartActions.loadCart(newCart));
    dispatch(orderActions.loadOrder({}));
    dispatch(cartActions.finishLoading());
  };
};
