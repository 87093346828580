import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

export default makeStyles((theme) => {
  return {
    navProductCategoryBar: {
      boxShadow: "4px 2px 10px rgba(0, 0, 0, 0.2)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: "white",
      position: "fixed",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-around",
    },

    navProductCategory: {
      marginTop: "0.5rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }
  };
});
