import { commerce } from '../lib/commerce';
import { productsActions } from './products-slice';

export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch(productsActions.startLoadingProducts());
    const { data } = await commerce.products.list();
    dispatch(productsActions.loadProducts(data));
    dispatch(productsActions.finishFetchingProducts());
    dispatch(productsActions.finishLoadingProducts());
  }
}

export const fetchCategories = () => {
  return async (dispatch) => {
    dispatch(productsActions.startLoadingCategories());
    const { data } = await commerce.categories.list();
    dispatch(productsActions.loadCategories(data));
    dispatch(productsActions.finishLoadingCategories());
  }
}

export const toggleNavProductsCategoriesWithDelay= (value) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(productsActions.toggleNavProductsCategories(value));
    }, 150);
  }
}