import React from "react";
import CarouselComponent from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import Image from "material-ui-image";
import Image1 from "./../../../assets/home-imgs/img1.jpg";
import Image2 from "./../../../assets/home-imgs/img2.jpg";
import Image3 from "./../../../assets/home-imgs/img3.jpg";
import { useTheme } from "@material-ui/core";
import useStyles from "./styles";

function Carousel(props) {
  const theme = useTheme();
  var items = [
    {
      src: Image1,
      alt: "img1",
    },
    {
      src: Image2,
      alt: "img2",
    },
    {
      src: Image3,
      alt: "img3",
    },
  ];

  return (
    <CarouselComponent
      fullHeightHover
      navButtonsAlwaysVisible
      timeout={300}
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "25%",
        },
      }}
      navButtonsWrapperProps={{
        // Move the buttons to the bottom. Unsetting top here to override default style.
        style: {
          bottom: "0",
          top: "unset",
        },
      }}
      indicatorIconButtonProps={{
        style: {
          padding: "10px", // 1
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.light
          },
        },
      }}
      indicatorContainerProps={{
        style: {
          position: 'absolute',
          bottom: "2%",
          textAlign: "center", // 4
        },
      }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} src={item.src} alt={item.alt} />
      ))}
    </CarouselComponent>
  );
}

function Item(props) {
  const classes = useStyles();
  return (
    <Paper>
      <div className={classes.imgContainer}>
        <Image
          src={props.src}
          alt={props.alt}
          cover
          animationDuration={30}
          imageStyle={{ height: "850px" }}
          aspectRatio={1}
        />
      </div>
    </Paper>
  );
}

export default Carousel;
