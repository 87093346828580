import React, { useState, useRef } from "react";
import SelectVariantGroup from "./SelectVariantGroup/SelectVariantGroup";
import SearchForVariantGroup from "./SearchForVariantGroup/SearchForVariantGroup";

const SelectMakeAndModel = ({ variantGroups }) => {
  const [selectedMake, setSelectedMake] = useState("");
  const [modelsByMake, setModelsByMake] = useState(null);
  const makeVariantGroup = variantGroups.find((variantGroup) =>
    variantGroup.name.includes("Make")
  );

  const searchForVariantGroupRef = useRef();

  // [ { name: "Honda Model", options: []}, {name: 'Ryobi Model, options: []} ]
  const allModelVariantGroups = variantGroups.filter((variantGroup) =>
    variantGroup.name.includes("Model")
  );


  const changeMakeHandler = (make) => {
    if (!make) {
      setModelsByMake(null);
      setSelectedMake("");
    }
    setSelectedMake(make);
    searchForVariantGroupRef.current.clearSearchInput();
    const filteredModelsByMake = allModelVariantGroups.find(variantGroup => variantGroup.name.includes(make));
    setModelsByMake(filteredModelsByMake);
  };

  return (
    <>
      <SelectVariantGroup
        variantGroup={makeVariantGroup}
        selectedValue={selectedMake}
        setSelectedValue={changeMakeHandler}
        helperText={"Choose the make of the device"}
      />
      <SearchForVariantGroup ref={searchForVariantGroupRef} variantGroup={modelsByMake} helperText={"Search for available model"}/>
    </>
  );
};

const SelectModel = ({variantGroups}) => {
  const [selectedModel, setSelectedModel] = useState("");
  const modelVariantGroup = variantGroups.find((variantGroup) =>
  variantGroup.name.includes("Model")
);
  return <SelectVariantGroup selectedValue={selectedModel} setSelectedValue={setSelectedModel} variantGroup={modelVariantGroup} helperText={"Select the model of the device"}/>
}


const VariantGroups = ({ variantGroups }) => {
  console.log("Variant Groups : ", variantGroups);

  if (variantGroups.length === 2) {
    return <SelectMakeAndModel variantGroups={variantGroups} />;
  }
  if (variantGroups.length === 1) {
    return <SelectModel variantGroups={variantGroups} />;
  }
  return <div></div>;
};

export default VariantGroups;
