import { authActions } from './auth-slice';
import axios from 'axios';

const signInUrl= `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_FIREBASE_KEY}`;

const refreshUrl = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_KEY}`

export const sendLoginData = (loginData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(signInUrl, {
        ...loginData,
        returnSecureToken: true
      }
        );
  
      dispatch(authActions.logIn(response.data.refreshToken));
    } catch (err) {
      dispatch(authActions.setInvalidResponse());
    }
  }
}

export const resendTokenAfterPageRefresh = () => {
  return async (dispatch) => {
    dispatch(authActions.startLoading());
    const token = localStorage.getItem('refreshToken');
    if (token && token.length > 0) {
      try {
        const response = await axios.post(refreshUrl, {
          grant_type: 'refresh_token',
          refresh_token: token
        });

        dispatch(authActions.logIn(response.data.refresh_token))
      } catch (err) {

      }
    }
    dispatch(authActions.finishLoading());
  }
}