import { Grid } from "@material-ui/core";
import Product from "./Product/Product";
import useStyles from "./styles";
import {useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';

const Products = () => {
  const classes = useStyles();
  const products = useSelector(state => state.products.products);
  const { search } = useLocation();
  let category = new URLSearchParams(search).get('category');
  
  return (
    <>
      <div className={classes.toolbar} />
      <main className={classes.content}>
        <Grid container justifyContent="center" spacing={4}>
          {products.filter((product)=> {
            if (!category) return true;
            const productCategories = product.categories.map(category => category.slug);
            return productCategories.includes(category);
            }).map((product) => {
            return (
              <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                <Product product={product}/>
              </Grid>
            );
          })}
        </Grid>
      </main>
    </>
  );
};

export default Products;
