import { Divider, Typography } from "@material-ui/core";
import React from "react";
import Image from "material-ui-image";
import useStyles from "./styles";
import { Link } from 'react-router-dom';
const AccordionProductCategory = ({ text, image, slug, onClickLink }) => {
  const classes = useStyles();
  return (
    <Link to={`/products?category=${slug}`}>
      <Divider />
      <div className={classes.productCategory} onClick={onClickLink}>
        <Image
          src={image}
          alt={text}
          spacing={0}
          style={{ padding: "2.5rem", marginLeft: '1rem', marginTop: '0.5rem' }}
          iconContainerStyle={{ padding: "0.75rem" }}
          imageStyle={{textAlign: "center", height: "90%", width: "90%" }}
        />
        <Typography variant="body1" color="textSecondary">{text}</Typography>
      </div>
      
    </Link>
  );
};

export default React.memo(AccordionProductCategory);
