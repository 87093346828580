import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, Typography } from "@material-ui/core";
import useStyles from "../../../main-styles";

const NavItem = ({ path, text, onClick }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.link}
      component={NavLink}
      exact
      activeClassName={classes.activeLink}
      to={path}
      onClick={onClick}
      style={{padding: '0 1rem'}}
    >
      {
        <Typography variant="h6" noWrap>
          {text}
        </Typography>
      }
    </ListItem>
  );
};

export default NavItem;
