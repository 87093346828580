import React from "react";
import { Container, Typography, Button, Grid, CssBaseline} from "@material-ui/core";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import CartItem from "./CartItem/CartItem";

import { useDispatch, useSelector } from "react-redux";
import { emptyCart } from "../../store/cart-actions";

const Cart = () => {
  const cart=  useSelector(state => state.cart.cart);
  let isEmpty = !cart?.line_items?.length;
  const classes = useStyles();
  const dispatch = useDispatch();


  const EmptyCart = () => {
    return (
      <>
        <Typography variant="subtitle1">
          You have no items in your shopping cart, start adding some
        </Typography>
        <br />
        <Button
          component={Link}
          to="/products"
          variant="outlined"
          type="button"
        >
          Back to Products
        </Button>
      </>
    );
  };

  const FilledCart = () => {
    return (
      <>
        <Grid container className={classes.content} >
          {cart.line_items.map((item) => {
            return (
              <Grid item xs={12} sm={4} key={item.id}  className={classes.item}>
                <CartItem
                  item={item}
                />
              </Grid>
            );
          })}
        </Grid>
        <div className={classes.cardDetails}>
          <Typography variant="h4">
            Subtotal: {cart.subtotal.formatted_with_symbol}
          </Typography>
          <div className={classes.cardButtons}>
            <Button
              className={classes.emptyButton}
              size="large"
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => dispatch(emptyCart())}
            >
              Empty Cart
            </Button>
            <Button
              className={classes.checkoutButton}
              size="large"
              type="button"
              variant="contained"
              color="primary"
              component={Link}
              to="/checkout"
            >
              Checkout
            </Button>
          </div>
        </div>
        <div className={classes.toolbar} />
      </>
    );
  };

  return (
    <> 
    <CssBaseline />
    <Container maxWidth="lg" align="center" className={classes.mobileContainer}>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h3" gutterBottom>
        Your Shopping Cart
      </Typography>
      {isEmpty ? <EmptyCart /> : <FilledCart />}
    </Container>
    </>
  );
};

export default Cart;
