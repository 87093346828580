import { Container, Typography, Divider, Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div className={classes.footer}>
      <Container maxWidth="md">
        <Grid container className={classes.row} spacing={4}  justifyContent="center" >
          <Grid item xs={12} sm={4}  className={classes.column}>
            <Typography variant="h4" gutterBottom={!isMobile}>
              Products
            </Typography>
            <ul>
              <li>Rechargable Battery</li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.column} justifyContent="center">
            <Typography variant="h4" gutterBottom={!isMobile}>
              Support
            </Typography>
            <ul>
              <li>Contact Us</li>
              <li>FAQ</li>
              <li>Shipping Policy</li>
              <li>Payment Policy</li>
              <li>Return & Refund Policy</li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.column}>
            <Typography variant="h4" gutterBottom={!isMobile}>
              Company
            </Typography>
            <ul>
              <li>Privacy Policy</li>
              <li>Terms of Use</li>
            </ul>
          </Grid>
        </Grid>
        <Divider className={classes.divider} variant="middle" />

        <div className={classes.row}>
          <Typography variant="p">
            &copy;{new Date().getFullYear()} The Bateria | All rights reserved
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
