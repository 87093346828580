import React, { useState } from 'react';
import Navbar from './Navbar/Navbar';
import SideDrawer from './SideDrawer/SideDrawer';
import Footer from './Footer/Footer';
import useStyles from './styles';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';

const Layout = (props) => {
  const classes = useStyles();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const toggleDrawerHandler = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerIsOpen(isOpen);
  };

  

  return (
    <div className={classes.pageContainer}>
      <Navbar toggleDrawer={toggleDrawerHandler}/>
      {isMobile && <SideDrawer toggleDrawer={toggleDrawerHandler} drawerIsOpen={drawerIsOpen} />}
      {props.children}
      <Footer  className={classes.contentWrap}/>
    </div>
  )
}

export default Layout
