import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionProductImage from "./AccordionProductCategory";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { productsActions } from "../../../../store/products-slice";

export default React.memo(function CategoriesAccordion({ onClickLink }) {
  const expanded = useSelector(
    (state) => state.products.productsAccordionIsOpen
  );
  const dispatch = useDispatch();
  const loadingCategories = useSelector(
    (state) => state.products.loadingCategories
  );
  const [batteryCategory] = useSelector((state) => state.products.categories); //10.11.2021: main category is Batteries, and it has property children with 4 items
  const productCategories = batteryCategory.children;

  const handleChange = (panel) => (event, isExpanded) => {
    //setExpanded(isExpanded ? panel : false);
    dispatch(
      productsActions.toggleProductsAccordion(isExpanded ? panel : false)
    );
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{ width: "33%", flexShrink: 0 }}
          >
            Products
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingCategories && <CircularProgress />}
          <Divider />
          <Link to="/products?category=batteries">
            <Typography
              variant="body1"
              onClick={onClickLink}
              color="textSecondary"
              style={{padding: '2rem 0 2rem 1rem'}}
              
            >
              All Batteries
            </Typography>
          </Link>
          {productCategories.map((productCategory) => (
            <AccordionProductImage
              key={productCategory.id}
              text={productCategory.name}
              image={productCategory.assets[0].url}
              slug={productCategory.slug}
              onClickLink={onClickLink}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});
