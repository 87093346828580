import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  Button,
  CardContent,
  Divider,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import useStyles from "./styles";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import ProductImageGallery from "./ProductImageGallery/ProductImageGallery";
import { addToCart } from "../../store/cart-actions";
import { useHistory } from "react-router-dom";
import VariantGroups from "./VariantGroups/VariantGroups";

const ProductDetailed = () => {
  const history = useHistory();
  const theme = useTheme();
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const product = useSelector((state) =>
    state.products.products.find((product) => product.id === params.id)
  );
  const loading = useSelector((state) => state.cart.loading);

  const handleAddToCart = () => {
    dispatch(addToCart(product.id, 1));
  };

  const handleBuyNow = () => {
    dispatch(addToCart(product.id, 1));
    history.push("/cart");
  };
  const variantGroups = product?.variant_groups.map((variant) => (
    {
    name: variant.name,
    options: variant.options,
    }
  )) || [];

  console.log(product.variant_groups);

  const productDetailed = (
    <>
      <div className={classes.toolbar} />
      <Grid container className={classes.container}>
        <Grid item xs={12} md={6}>
          <ProductImageGallery images={product.assets.slice(1)} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardContent}>
            <Typography variant="h3" gutterBottom>
              {product.name}
            </Typography>
            <Divider />
            <CardContent>
              <Typography variant="text" className={classes.priceText}>
                Price:
              </Typography>
              <Typography variant="text" className={classes.priceDigits}>
                {product.price.formatted_with_symbol}
              </Typography>
              <VariantGroups variantGroups={variantGroups} />

              <div className={classes.actionButtons}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={handleAddToCart}
                >
                  {loading ? (
                    <CircularProgress size="1.75rem" color="white" />
                  ) : (
                    <Typography variant="h5">Add to Cart</Typography>
                  )}
                </Button>
                <Button
                  variant="contained"
                  color={theme.palette.secondary.light}
                  className={classes.actionButton}
                  onClick={handleBuyNow}
                >
                  <Typography variant="h5">Buy Now</Typography>
                </Button>
              </div>

              <div className={classes.description}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: product.description }}
                  variant="body1"
                  color="textSecondary"
                  gutterBottom
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </>
  );
  return (
    <Container maxWidth="lg" align="center">
      {productDetailed}
    </Container>
  );
};

export default ProductDetailed;
