import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useStyles from "../../../../main-styles";
import { ListItem, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from 'react-redux';
import { toggleNavProductsCategoriesWithDelay } from '../../../../../store/products-actions';

const ProductsNavItem = () => {
  const classes = useStyles();
  const showCategories = useSelector(state => state.products.showCategories);
  const [rotateExpandMoreIcon, setRotateExpandMoreIcon] = useState(false);
  const dispatch = useDispatch();
    const toggleProductsCategoryBar = (value) => {
      dispatch(toggleNavProductsCategoriesWithDelay(value));
  };

  useEffect(() =>{
    const timer = setTimeout(() => {
      setRotateExpandMoreIcon(showCategories);
    }, 100);
    return () => {
      clearTimeout(timer);
    }
  }, [showCategories])

  return (
    <ListItem
      className={classes.link}
      component={NavLink}
      exact
      activeClassName={classes.activeLink}
      to="/products"
      style={{padding: '0 1rem'}}
    >
      <span
        onMouseEnter={() => toggleProductsCategoryBar(true)}
        onMouseLeave={() => toggleProductsCategoryBar(false)}
        className={classes.productsNavItemWithIcon}
      >
        <Typography variant="h6" noWrap>
          Products
        </Typography>
        <ExpandMoreIcon className={`${classes.expandMoreIcon} ${rotateExpandMoreIcon ? classes.expandMoreRotated : ""}`}/>
      </span>
    </ListItem>
  );
};

export default ProductsNavItem;
