import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
  toolbar: theme.mixins.toolbar,
  mobileContainer: {
    [theme.breakpoints.down('xs')] : {
      padding: '0',
    }
    
  },
  content: {
    margin: '0',
    background: '#eee',
    padding: theme.spacing(3),
  },

  item: {
    padding: '0 0.5rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0.5rem',
    }
  },

  title: {
    marginTop: '5%',
  },
  
  cardButtons: {
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      flexDirection: 'column',
    }
  },

  emptyButton: {
    minWidth: '150px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
      marginBottom: '5px',
    },
  },
  checkoutButton: {
    minWidth: '150px',
  },
  cardDetails: {
    display: 'flex',
    marginTop: '2%',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')] : {
      justifyContent: 'space-around',
    }
  },
  link: {
    color: 'inherit',
    '&:visited': {
      color: 'inherit',
    }
  }
}});