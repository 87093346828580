import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';

const SearchForVariantGroup = ({variantGroup, helperText}, ref) => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [loading, setLoading] = useState(false);

  const isDisabled = variantGroup?.options ? false : true;

  const textFieldRef = useRef();

  useImperativeHandle(ref, () => ({
    clearSearchInput: () => {
      console.log("clearSearchInput");
      textFieldRef.current.value = "";
      setSearchInput("");
      setSelectedModel("");
    }
  }));

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  }

  return (
    <>
    <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        clearOnBlur
        autoComplete
        value={selectedModel}
        onChange={(event, newValue) => {
          setSelectedModel(newValue);
        }}
        disabled={isDisabled}
        loading={loading}
        filterOptions={(options, state) => {
          if (searchInput.trim().length > 1) {
            setLoading(true);
          return options.filter(option => {
            const searchInputArray = searchInput.trim().toLowerCase().split(',').join(" ").split(' ');
            let optionContainsAllInputWords = true;
            for (const searchInputWord of searchInputArray) {
              if (!option.toLowerCase().includes(searchInputWord)) {
              optionContainsAllInputWords = false;
              break;
              }
            }
            setLoading(false);
            return optionContainsAllInputWords;
          })} else { 
            setLoading(false);
            return [];}
        }}
        filterSelectedOptions={true}
        options={variantGroup?.options ? variantGroup?.options.map((option) => option.name) : []}
        sx={{ marginBottom: 1, marginTop: '2rem', minWidth: 120 }}
        renderInput={(params) => (
          <TextField
            ref={textFieldRef}
            {...params}
            label={isDisabled ? "Select the make first" : "Select the model"}
            value={searchInput}
            onChange={handleInputChange}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      <FormHelperText>{helperText || ""}</FormHelperText>
      </>
      
  )
}
export default forwardRef(SearchForVariantGroup);