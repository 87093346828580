import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectVariantGroup({ variantGroup, helperText, selectedValue, setSelectedValue }) {

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const optionsList = variantGroup.options.map(option => <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>);
  //TODO: do not make just the first variant appear, make multiple Select Components based on variants (Year, Make etc.)

  return (
    <div>
      <FormControl sx={{ m: 1, marginLeft: '0', marginTop: '2rem', minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">{variantGroup.name}</InputLabel>
        <Select
          variant="outlined"
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={selectedValue}
          label={variantGroup.name}
          onChange={handleChange}
        >
          {optionsList}
        </Select>
        <FormHelperText sx={{marginLeft: 0}}>{helperText || ""}</FormHelperText>
      </FormControl>
    </div>
  );
}