import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  grid: {
    position: "relative"
  },
  spinner: {
  position: "absolute",
  right: "0",
  marginRight: "2rem",
  height: "10px"
  }
}));