import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  
  container : {
    marginTop: '2%'
  },

  cardHeader: {
    paddingBottom: theme.spacing(3),
  },

  cardContent: {
    padding: theme.spacing(5),
    textAlign: "left",
  },

  title: {
    marginTop: "5%",
  },

  galleryContainer: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },

  priceText: {
    fontSize: 28,
  },

  priceDigits: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: 28
  },
  
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },

  actionButton: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(6),
  }
}));
