import React from 'react'
import { ReactComponent as SloganSvg} from '../../../assets/slogan.svg';
import { Container } from '@material-ui/core';
const Slogan = () => {
  return (
    <Container className="center">
     <SloganSvg style={{height: '750px', width: '100%'}}/> 
    </Container>
  )
}

export default Slogan;