import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "./FormInput";
import SelectInput from "./SelectInput";


import { commerce } from "../../../lib/commerce";


const AddressForm = ({ checkoutToken, next }) => {
  

  const [shippingCountries, setShippingCountries] = useState([]);
  const [shippingCountry, setShippingCountry] = useState("");
  const [loadCountries, setLoadCountries] = useState(false);

  const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
  const [shippingSubdivision, setShippingSubdivision] = useState("");
  const [loadSubdivisions, setLoadSubdivisions] = useState(false);

  const [shippingOptions, setShippingOptions] = useState([]);
  const [shippingOption, setShippingOption] = useState("");
  const [loadOptions, setLoadOptions] = useState(false);

  const handleChangeCountry = (e) => {
    setShippingCountry(e.target.value);
  };

  const handleChangeSubdivision = (e) => {
    setShippingSubdivision(e.target.value);
  };

  const handleChangeOption = (e) => {
    setShippingOption(e.target.value);
  };

  const fetchShippingCountries = useCallback(async (checkoutTokenId) => {
    setLoadCountries(true);
    
    const { countries } = await commerce.services.localeListShippingCountries(
      checkoutTokenId
    );
    setLoadCountries(false);
    
    setShippingCountries(countries);
    setShippingCountry(Object.keys(countries).find(key => key === 'US'));
  }, []);

  const fetchSubdivisions = useCallback(async (countryCode) => {
    setLoadSubdivisions(true);
    const { subdivisions } = await commerce.services.localeListSubdivisions(
      countryCode
    );
    setLoadSubdivisions(false);
    setShippingSubdivisions(subdivisions);
    setShippingSubdivision(Object.keys(subdivisions)[0]);
  }, []);

  const fetchShippingOptions = useCallback(async (checkoutTokenId, country, region=null) => {
    setLoadOptions(true);
    const options = await commerce.checkout.getShippingOptions(checkoutTokenId, { country, region });
    setLoadOptions(false);
    setShippingOptions(options);
    setShippingOption(options[0].id);
    
  }, []);


  useEffect(() => {
    fetchShippingCountries(checkoutToken.id);
    
  }, [checkoutToken.id, fetchShippingCountries]);

  useEffect(() => {
    if (shippingCountry) {
      fetchSubdivisions(shippingCountry);
    }
  }, [fetchSubdivisions, shippingCountry]);

  useEffect(() => {
    if(shippingSubdivision) {
      fetchShippingOptions(checkoutToken.id, shippingCountry, shippingSubdivision);
    }
  }, [checkoutToken.id, fetchShippingOptions, shippingCountry, shippingSubdivision]);

  const countries = Object.entries(shippingCountries).map(([code, name]) => ({
    id: code,
    label: name,
  }));
  const subdivisions = Object.entries(shippingSubdivisions).map(
    ([code, name]) => ({ id: code, label: name })
  );
  const options = shippingOptions.map(option => ({
    id: option.id, 
    label: `${option.description} - ${option.price.formatted_with_symbol}`
  }));

  const methods = useForm();
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Shipping Address
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) =>  next({
        ...data,
        shippingCountry,
        shippingSubdivision,
        shippingOption 
      }))}>
          <Grid container spacing={3}>
            <FormInput required name="firstName" label="First Name" />
            <FormInput required name="lastName" label="Last Name" />
            <FormInput required name="address1" label="Street Address" />
            <FormInput required name="city" label="City" />
            <FormInput required name="zip" label="ZIP Code" />
            <FormInput required name="email" label="E-mail Address" />
            <SelectInput
              values={countries}
              selectedValue={shippingCountry}
              onChange={handleChangeCountry}
              label="Shipping Country"
              loading={loadCountries}
            ></SelectInput>
            <SelectInput
              values={subdivisions}
              selectedValue={shippingSubdivision}
              onChange={handleChangeSubdivision}
              label="Shipping Subdivision"
              loading={loadSubdivisions}
            ></SelectInput>
            <SelectInput
              values={options}
              selectedValue={shippingOption}
              onChange={handleChangeOption}
              label="Shipping Option"
              loading={loadOptions}
            ></SelectInput>
          </Grid>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between'}} >
            <Button component={Link} to="/cart" variant="outlined">Back to Cart</Button>
            <Button type="submit" variant="contained" color="primary"> Next</Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default AddressForm;
