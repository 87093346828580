import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    color: 'white',
    margin: '0',
    background: '#222',
    padding: `10rem 2rem`,
    textAlign: 'center'
  },

  row: {
    textAlign: 'center'
  }
}));